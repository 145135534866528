import axios from "@/axios";
export function getVipList() {
  const url = "live_member_combo/view.json";
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
export function getGoldList() {
  const url = "live_gold_combo/view.json";
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
export function userPay(params) {
  const url = "payment/userRecharge.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function goldPay(params) {
  const url = "payment/userGoldRecharge.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getGoldBillList(params) {
  const url = "payment/userConsumptionLog.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getDppBillList(params) {
  const url = "live_user/dppRecordDetail.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function dppChangeGold(params) {
  const url = "live_user/gold_coin_exchange.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function goldRechargeMember(params) {
  const url = "live_gold_combo/goldRechargeMember.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
